import React from 'react';

const Contact = ()=>{
	return(
		<div style={{color:'whitesmoke', height: '75vh', textAlign: 'center'}}>
			<h2>Contact Us !</h2>
		</div>
	);
};

export default Contact;