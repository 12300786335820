import React from 'react';

const Request = ()=>{
	return(
		<div style={{color:'whitesmoke', height: '75vh', textAlign: 'center'}}>
			<h2>Coming Soon !</h2>
		</div>
	);
};

export default Request;