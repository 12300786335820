import React from 'react';

const Terms = ()=>{
	return(
		<div style={{color:'whitesmoke', height: '75vh', textAlign: 'center'}}>
			<h2 >Disclaimer</h2>
			<p>This Site is Absolutely Legal and Contain Only Links to Other Sites on The Internet: (netu.tv, openload.co, rapidvideo.com). We do Not Host or Upload any Video, Films, Media Files. This Site is Not Responsible for The Accuracy, Compliance, Copyright, Legality, Decency, or Any Other Aspect of The Content of Other Linked Sites. If You Have Any Legal Issues Please Contact The Appropriate Media File Owners or Host Sites.</p>
		</div>
	);
};

export default Terms;